/* eslint-disable @typescript-eslint/no-inferrable-types */
export const ADMIN_EMAIL: string = 'admin@ledsreact.com';
export const FIRST_USER_EMAIL: string = 'appstore@ledsreact.com';
export const SUPPORT_EMAIL: string = 'support@ledsreact.com';
export const DAYS_BEFORE_REQUIRED_RELEASE: number = 10;
export const REQUEST_ID_TOKEN_HEADER: string = 'x-request-id';
export const S3_CSV_EXPORT_PATH: string = 'csv-exports';
export const S3_PLAYERS_CSV_IMPORTS_PATH: string = 'players-csv-imports';
export const S3_PLAYERS_CSV_TEMPLATE_PATH: string = 'players-import-template';
export const S3_CSV_EXERCISE_ATTEMPT_PATH: string = 'exercises';
export const S3_TEMP_EXPORT_PATH: string = 'tmp-exports';
export const VERSION_REGEX: RegExp = new RegExp(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/);

export const METRIC_UNIT: Record<string, string> = {
  total_time_s: 's',
  totaltimereactiontime_s: 's',
  reactiontime_s: 's',
  avg_speed_ms: 'm/s',
  max_speed_ms: 'm/s',
  avg_acceleration_ms2: 'm/s²',
  max_acceleration_ms2: 'm/s²',
  p_max: 'W/kg',
  f0: 'N/kg',
  v0_ms: 'm/s',
};

export const METRIC_POSITIVE_TREND: Record<string, string> = {
  total_time_s: 'bad',
  totaltimereactiontime_s: 'bad',
  reactiontime_s: 'bad',
  avg_speed_ms: 'good',
  max_speed_ms: 'good',
  avg_acceleration_ms2: 'good',
  max_acceleration_ms2: 'good',
  p_max: 'good',
  f0: 'good',
  v0_ms: 'good',
};
