import { registerDecorator } from 'class-validator';
import { VERSION_REGEX } from '@ledsreact/constants';

export function IsVersionNumber() {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: 'isVersionNumber',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(version: string) {
          return VERSION_REGEX.test(version);
        },
        defaultMessage(): string {
          return '($value) is not a correct version number ! (ex: 1.0.0)';
        },
      },
    });
  };
}
